import React, { FC, useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';

import { ProductPageTypes } from '@shared/types/umbraco/content/productPage';
import GatsbyImage from 'common/GatsbyImage';
import Button from 'common/Button';
import { gtmService } from 'utils/gtmService';
import useScreenRecognition from 'hooks/useScreenRecognition';

import './WTBCards.scss';

const WTBCards: FC<ProductPageTypes.IWTBCards> = ({
  availableLabel,
  availableLinks,
  retailersToShowDesctop,
  retailersToShowMobile,
  ctaLabelLess,
  ctaAriaLabelLess,
  ctaLabel,
  ctaAriaLabel,
  isAlternative,
}) => {
  const [retailersToShow, setRetailersToShow] = useState<number>();
  const { isDesktop } = useScreenRecognition();
  const limit = isDesktop ? retailersToShowDesctop : retailersToShowMobile;
  const clickHandler = useCallback(
    (name) => () => {
      gtmService.emitRetailerClick(name);
    },
    []
  );
  useEffect(() => {
    setRetailersToShow(limit > 0 ? limit : availableLinks.length);
  }, [limit]);

  const handleLoadMore = useCallback(() => {
    const count = limit === retailersToShow ? availableLinks.length : limit;
    setRetailersToShow(count);
  }, [retailersToShow]);

  return (
    <div
      className={classnames('wtb-cards', {
        'wtb-cards--alternative': isAlternative,
      })}
      data-testid="wtb-cards"
    >
      {availableLabel ? <strong className="wtb-cards__label">{availableLabel}</strong> : null}
      <div className="wtb-cards__links">
        {availableLinks?.slice(0, retailersToShow).map(
          ({
            properties: {
              image: { logo, ariaLabel, imageAlt },
              link,
            },
          }) => (
              <Button
                to={link?.[0]?.queryString ? link?.[0]?.url + link[0].queryString : link?.[0]?.url}
                target={link?.[0]?.target}
                key={imageAlt + ariaLabel}
                variant="retailer"
                ariaLabel={ariaLabel}
                clickHandler={clickHandler(link?.[0]?.name)}
              >
                <GatsbyImage
                  image={logo}
                  objectFit="contain"
                  className="wtb-cards__image"
                  alt={imageAlt}
                />
              </Button>
          )
        )}
      </div>

      {availableLinks.length > 0 && ctaLabel && limit < availableLinks.length ? (
        <div className="wtb-cards__more">
          <Button
            ariaLabel={limit === retailersToShow ? ctaAriaLabel : ctaAriaLabelLess}
            clickHandler={handleLoadMore}
            classes="wtb-cards__btn"
            variant="secondary"
            iconSuffix="chevron-right"
            className={classnames('wtb-cards__btn', {
              'wtb-cards__btn--active': limit !== retailersToShow,
            })}
          >
            {limit === retailersToShow ? ctaLabel : ctaLabelLess}
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default WTBCards;
